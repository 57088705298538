import React from "react";
import Settings from "../pages/settings";
import EventListDashboard from "../pages/event";
import EventBudgetOverview from "../pages/budget/EventBudgetOverview";
import TransactionProvider from "../providers/TransactionProvider";
import Page from "@jumbo/shared/Page";
import ExpenseByCategory from "../pages/budget/ExpenseByCategory";
import CreateEvent from "../pages/event/CreateEvent";

/**
 routes which you want to make accessible to both authenticated and anonymous users
 **/
const routesForPublic = [];

/**
 routes only accessible to authenticated users
 **/
const routesForAuthenticatedOnly = [
    {
        path: "/",
        element: <Page component={() => <TransactionProvider><ExpenseByCategory budgetId={"632be953dab50c6c8b015d80"}/></TransactionProvider>}/>
    },
    {
        path: "/events",
        element: <Page component={EventListDashboard}/>,
    },
    {
        path: "/events/create",
        element: <Page component={CreateEvent}/>,
    },
    {
        path: "/budget/:id",
        element: <Page component={() => <TransactionProvider><EventBudgetOverview/></TransactionProvider>}/>
    },
    {
        path: "/settings",
        element: <Page component={Settings}/>
    }
];


/**
 routes only accessible when user is anonymous
 **/
const routesForNotAuthenticatedOnly = [];


const routes = [
    ...routesForPublic,
    ...routesForAuthenticatedOnly,
    ...routesForNotAuthenticatedOnly,
];

export {routes as default, routesForPublic, routesForNotAuthenticatedOnly, routesForAuthenticatedOnly};