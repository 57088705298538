import React, {useCallback, useEffect, useState} from "react";
import JumboListItem from "@jumbo/components/JumboList/components/JumboListItem";
import {ListItemIcon, ListItemText, Typography} from "@mui/material";
import {useJumboTheme} from "@jumbo/hooks";
import moment from "moment";
import Div from "@jumbo/shared/Div";
import Span from "@jumbo/shared/Span";
import {Comment, Lock, LockOpen} from "@mui/icons-material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import Badge from "@mui/material/Badge";
import Tooltip, {tooltipClasses} from '@mui/material/Tooltip';
import {styled} from '@mui/material/styles';
import {useJumboDialog} from "@jumbo/components/JumboDialog/hooks/useJumboDialog";
import TransactionDetail from "./TransactionDetail";
import TransactionCategoryChip from "../../components/TransactionCategoryChip";
import {useMutation} from "@tanstack/react-query";
import {transactionService} from "../../services/transaction-service";
import {useSnackbar} from "notistack";
import IconButton from "@mui/material/IconButton";
import {useTransaction} from "../../providers/TransactionProvider";
import TransactionTypeIcon from "../../components/TransactionTypeIcon";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';


const TransactionCommentListItem = ({items = []}) => {
    const [comments] = useState(items);

    const HtmlTooltip = styled(({className, ...props}) => (
        <Tooltip {...props} classes={{popper: className}}/>
    ))(({theme}) => ({
        [`& .${tooltipClasses.tooltip}`]: {
            backgroundColor: '#f5f5f9',
            color: 'rgba(0, 0, 0, 0.87)',
            maxWidth: 500,
            fontSize: theme.typography.pxToRem(12),
            border: '1px solid #dadde9',

        },
    }));
    return (
        <ListItemIcon>
            {comments &&
                <HtmlTooltip
                    title={
                        <React.Fragment>
                            {comments.map((comment, i) => {
                                return (
                                    <Div>
                                        <Typography color="inherit">
                                            <Typography>
                                                {comment}
                                            </Typography>
                                        </Typography>
                                    </Div>
                                );
                            })}

                        </React.Fragment>
                    }
                >
                    <Badge badgeContent={comments.length} color="primary">
                        <Comment fontSize="small" color="action"/>
                    </Badge>
                </HtmlTooltip>
            }
        </ListItemIcon>
    );
}

const TransactionListingItem = ({item, budgetId}) => {
    const [transaction] = useState(item);
    const {theme} = useJumboTheme();
    const {showDialog, hideDialog} = useJumboDialog();
    const {enqueueSnackbar} = useSnackbar();
    const {setRefreshTransactionList} = useTransaction();
    const [isLocked, setLock] = useState(false);

    useEffect(() => {
        setLock(item.lock)
    }, [])

    const lockTransaction = useCallback((lock) => {
        setLock(!lock);
    }, [isLocked]);

    const showTransactionDetail = React.useCallback(() => {
        showDialog({
            content: <TransactionDetail transaction={transaction} onClose={hideDialog}/>
        })
    }, [showDialog, transaction]);

    const lockMutation = useMutation(transactionService.lock, {
        onSuccess: () => {
            lockTransaction(isLocked);
            setRefreshTransactionList(true);
            enqueueSnackbar(`Transaction ${transaction.name} has been locked.`,
                {
                    variant: 'success',
                    autoHideDuration: 3000
                },
            );
        }
    });

    const duplicateMutation = useMutation(transactionService.duplicate, {
        onSuccess: () => {
            setRefreshTransactionList(true);
            enqueueSnackbar(`Transaction ${transaction.name} has been successfully duplicated.`,
                {
                    variant: 'success',
                    autoHideDuration: 3000
                },
            );
        }
    });

    const unLockMutation = useMutation(transactionService.unlock, {
        onSuccess: () => {
            lockTransaction(isLocked);
            setRefreshTransactionList(true);
            enqueueSnackbar(`Transaction ${transaction.name} has been unlocked.`,
                {
                    variant: 'success',
                    autoHideDuration: 3000
                },
            );
        }
    });

    const deleteMutation = useMutation(({
                                            budgetId,
                                            transactionId
                                        }) => transactionService.deleteTransaction(budgetId, transactionId), {
        onSuccess: () => {
            setRefreshTransactionList(true);
            enqueueSnackbar(`Transaction ${transaction.name} has been successfully deleted.`,
                {
                    variant: 'success',
                    autoHideDuration: 3000
                },
            );
        }
    });

    const onTransactionLock = (transactionId) => {
        setTimeout(() => {
            lockMutation.mutate(transactionId);
        }, 400);
    };

    const onTransactionDuplicate = (transactionId) => {
        setTimeout(() => {
            duplicateMutation.mutate(transactionId);
        }, 400);
    };

    const onUnlockTransaction = (transactionId) => {
        setTimeout(() => {
            unLockMutation.mutate(transactionId);
        }, 400);
    }
    const onDeleteTransaction = (budgetId, transactionId) => {
        setTimeout(() => {
            deleteMutation.mutate({budgetId, transactionId});
        }, 400);
    }

    return (
        <React.Fragment>
            <JumboListItem
                // disabled
                // itemData={transaction}
                componentElement={"div"}
                sx={{
                    cursor: 'pointer',
                    borderTop: 1,
                    borderTopColor: 'divider',

                    '&:hover': {
                        bgcolor: 'action.hover',

                        '& .ListAction': {
                            width: {sm: '100%'},
                            opacity: {sm: 1}
                        },

                        '& .ListTextExtra': {
                            visibility: {sm: 'hidden'},
                            opacity: {sm: 0},
                        }
                    },

                    '& .MuiListItemIcon-root': {
                        minWidth: 48
                    },

                    [theme.breakpoints.down('sm')]: {
                        flexWrap: 'wrap'
                    }
                }}
            >
                <ListItemText
                    title={transaction?.name}
                    onClick={showTransactionDetail}
                    primary={
                        <Typography variant={"body1"} component={"div"}>
                            <Typography variant={"h5"} noWrap>
                                {transaction?.name} {isLocked && <Lock sx={{color: 'warning.light'}}/>}
                            </Typography>

                            <Typography
                                variant={'h6'}
                                color={'text.secondary'}
                                noWrap
                                sx={{my: -1}}
                            >
                                ${Intl.NumberFormat('fr-CA').format(transaction?.amount ? transaction?.amount : transaction?.estimated)} {transaction?.currency}
                                <TransactionTypeIcon type={transaction?.type}/>
                            </Typography>
                        </Typography>
                    }
                    sx={{flex: 1}}
                />
                <TransactionCommentListItem items={transaction?.comments}/>
                <Div
                    sx={{
                        width: 160,
                        display: 'flex',
                        flexShrink: '0',
                        position: 'relative',
                        justifyContent: 'flex-end',

                        [theme.breakpoints.down('sm')]: {
                            width: '100%',
                            justifyContent: 'space-between'
                        }
                    }}
                >
                    <Div
                        sx={{

                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'flex-end',
                            flex: 1,
                            transition: 'all 0.5s ease',

                            [theme.breakpoints.down('sm')]: {
                                justifyContent: 'flex-start',
                                ml: 6
                            }
                        }}
                        className={'ListTextExtra'}
                    >
                        <TransactionCategoryChip item={transaction?.category?.name}
                                                 value={transaction?.category?.color}/>

                        <Typography
                            variant={'body1'}
                            color={'text.secondary'}
                            ml={2}
                            noWrap
                        >
                            {moment(transaction?.lastModifiedDate).format("lll")}
                        </Typography>
                    </Div>
                    <Div
                        className='ListAction'
                        sx={{
                            display: 'flex',

                            [theme.breakpoints.up('sm')]: {
                                position: 'absolute',
                                top: '50%',
                                width: 0,
                                opacity: 0,
                                overflow: 'hidden',
                                transition: 'all 0.5s ease',
                                justifyContent: 'flex-end',
                                transform: 'translateY(-50%)',
                            }
                        }}
                    >
                        <Span sx={{height: 36, overflow: 'hidden'}}>
                            {isLocked &&
                                <LockActions handleUnLock={() => onUnlockTransaction(transaction?.id)}/>}
                            {!isLocked &&
                                <UnlockActions
                                    handleLock={() => onTransactionLock(transaction?.id)}
                                    handleCopy={() => onTransactionDuplicate(transaction?.id)}
                                    handleEdit={() => console.log("Edit called", transaction?.id)}
                                    handleDelete={() => onDeleteTransaction(budgetId, transaction?.id)}
                                />}
                        </Span>
                    </Div>
                </Div>
            </JumboListItem>
        </React.Fragment>
    );
}

const UnlockActions = ({handleCopy, handleLock, handleEdit, handleDelete}) => {
    return (
        <React.Fragment>
            <Tooltip title={"Lock"}>
                <IconButton onClick={handleLock}>
                    <Lock fontSize={"small"}/>
                </IconButton>
            </Tooltip>
            <Tooltip title={"Copy"}>
                <IconButton onClick={handleCopy}>
                    <ContentCopyIcon fontSize={"small"}/>
                </IconButton>
            </Tooltip>
            <Tooltip title="Edit">
                <IconButton onClick={handleEdit}>
                    <EditIcon fontSize={"small"}/>
                </IconButton>
            </Tooltip>
            <Tooltip title="Delete">
                <IconButton onClick={handleDelete}>
                    <DeleteIcon fontSize={"small"}/>
                </IconButton>
            </Tooltip>
        </React.Fragment>
    );
}

const LockActions = ({handleUnLock}) => {
    return (
        <Tooltip title={"Unlock"}>
            <IconButton onClick={handleUnLock}>
                <LockOpen fontSize={"small"}/>
            </IconButton>
        </Tooltip>
    )
}
export default TransactionListingItem;