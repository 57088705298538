import React from 'react';
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import CardHeader from "@mui/material/CardHeader";
import {alpha, ListItem, ListItemAvatar, ListItemText, Typography} from "@mui/material";
import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import Stack from "@mui/material/Stack";
import Div from "@jumbo/shared/Div";
import {AttachMoney, Comment, TrendingDownOutlined, TrendingUpOutlined} from "@mui/icons-material";
import TransactionCategoryChip from "../../components/TransactionCategoryChip";
import moment from "moment";

const TransactionIcon = ({type}) => {
    const avatarProps = {
        bgcolor: type === "revenue" ? "success.main" : "error.main",
        transition: 'all 0.3s ease-in-out',
        color: 'common.white',
    };

    const transactionTypeIcon = type === "revenue" ?
        <TrendingUpOutlined fontSize="large" sx={{ml: .3}}/>
        : <TrendingDownOutlined fontSize="large" sx={{ml: .3}}/>
    return (
        <Div sx={{
            display: 'flex',
            width: 95,
            alignItems: 'center',
            justifyContent: 'center',
        }}>
            <Avatar variant={"circular"} sx={{width: 56, height: 56, ...avatarProps}}>
                {transactionTypeIcon}
            </Avatar>
        </Div>
    );
}
const TransactionDetail = ({transaction, onClose}) => {

    const type = transaction?.type === "revenue" ? "Revenue" : "Expense";
    const transactionTypeIcon = transaction?.type === "revenue" ?
        <TrendingUpOutlined fontSize="large" sx={{ml: .3, color: 'primary.light'}}/>
        : <TrendingDownOutlined fontSize="large" sx={{ml: .3, color: 'primary.light'}}/>;

    const comments = transaction?.comments ? transaction?.comments : ["No comments"];

    return (
        <Div sx={{m: theme => theme.spacing(-2.5, -3)}}>
            <CardHeader
                title={
                    <Stack>
                        <Typography variant={"h6"} align={"center"}>{transaction?.name}</Typography>
                        <Typography component={"div"} align={"center"}>
                            <TransactionCategoryChip item={transaction?.category?.name}
                                                     value={transaction?.category?.hex}/>
                        </Typography>
                    </Stack>
                }
                subheader={<Divider sx={{mt: 1.5, color: theme => alpha(theme.palette.primary.main, .15)}}/>}
                action={
                    <IconButton onClick={onClose}><CloseIcon/></IconButton>
                }
            />
            <List disablePadding>
                <ListItem sx={{px: 4}}>
                    <ListItemAvatar sx={{minWidth: 66}}>
                        <Avatar
                            variant="rounded"
                            sx={{
                                height: 48,
                                width: 48,
                                bgcolor: theme => alpha(theme.palette.primary.main, .15)
                            }}
                        >
                            {/*<AttachMoney sx={{color: 'primary.light'}}/>*/}
                            {transactionTypeIcon}
                        </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                        primary={<Typography variant={"body1"} color={"text.secondary"} mb={.5}>{type}</Typography>}
                        secondary={<Typography variant={"h5"}
                                               mb={0}>{`$${transaction.amount ? transaction.amount : transaction?.estimated}`}</Typography>}
                    />
                </ListItem>
                <Divider component={"li"}/>
                <ListItem sx={{px: 4}}>
                    <ListItemAvatar sx={{minWidth: 66}}>
                        <Avatar
                            variant="rounded"
                            sx={{
                                height: 48,
                                width: 48,
                                bgcolor: theme => alpha(theme.palette.primary.main, .15)
                            }}
                        >
                            <AttachMoney sx={{color: 'primary.light'}}/>
                        </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                        primary={<Typography variant={"body1"} color={"text.secondary"} mb={.5}>Currency</Typography>}
                        secondary={<Typography variant={"h5"} mb={0}>{transaction?.currency}</Typography>}
                    />
                </ListItem>
                <Divider component={"li"}/>
                <ListItem sx={{px: 4}}>
                    <ListItemAvatar sx={{minWidth: 66}}>
                        <Avatar
                            variant="rounded"
                            sx={{
                                height: 48,
                                width: 48,
                                bgcolor: theme => alpha(theme.palette.primary.main, .15)
                            }}
                        >
                            <Comment sx={{color: 'primary.light'}}/>
                        </Avatar>
                    </ListItemAvatar>
                    <ListItemText

                        primary={<Typography variant={"body1"} color={"text.secondary"} mb={.5}>Comments</Typography>}
                        secondary={comments.map((comment, idx) => {
                            return (
                                <Typography key={idx} component={"div"}>
                                    {comment}
                                </Typography>
                            )
                        })
                        }
                    />
                </ListItem>
                <Divider component={"li"}/>
            </List>
            <Typography
                variant={"body1"}
                component={"div"}
                sx={{px: 4, py: 2}}
            >
                <Typography
                    align={"right"}
                    variant={"body1"}
                    color={'text.secondary'}
                    noWrap
                >
                    {`${moment(transaction?.lastModifiedDate).format("lll")}, by ${transaction.modifiedByUser ? transaction.modifiedByUser : 'generated'}`}
                </Typography>
            </Typography>
        </Div>
    );
};

export default TransactionDetail;
