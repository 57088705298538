import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';
import Backend from 'i18next-http-backend';

i18n
    .use(Backend)
    .use(initReactI18next)
    .init({
        fallbackLng: 'en',
        debug: process.env.NODE_ENV !== 'production',
        appendNamespaceToMissingKey: true,
        missingKeyNoValueFallbackToKey: true,
        missingKeyHandler: (lngs, ns, key, fallbackValue) => `${key}.missing`
    });

export default i18n;