import {Alert} from "@mui/material";
import Stack from "@mui/material/Stack";

const ProfitSharing = ({budgetId, profit}) => {

    return (
        <>
            <AlertProfit profit={profit}/>
        </>
    );
}

const AlertProfit = ({profit}) => {

    const severity = profit < 0 ? "error" : "success";
    const text = profit < 0 ? "No profit !!!!" : "Good news there's some profit to split";

    return (
        <Stack sx={{width: '100%'}} spacing={2}>
            <Alert variant="filled" severity={severity}>
                {text}
            </Alert>
        </Stack>
    )
}

export default ProfitSharing;