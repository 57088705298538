import * as React from "react";
import {FilledInput, FormControl, InputLabel} from "@mui/material";
import {Controller, get, useFormContext} from "react-hook-form";

export const ControlledFiledInputField = ({label, ...props}) => {
    const {control, formState} = useFormContext();
    const error = get(formState.errors, props.name);
    const {required, fullWidth,} = props;

    return (
        <Controller
            {...props}
            control={control}
            defaultValue={props.defaultValue}
            render={({field}) => (
                <FormControl sx={{width: '25ch'}} variant="filled" required={required} fullWidth={fullWidth}>
                    <InputLabel>{label}</InputLabel>
                    <FilledInput
                        {...props}
                        {...field}
                        error={!!error}
                    />
                </FormControl>
            )}
        />
    );
};

export default ControlledFiledInputField;
