import React, {Suspense} from 'react';
import ReactDOM from 'react-dom';
import reportWebVitals from './reportWebVitals';
import App from "./app/App";
import './app/config/i18n';
import Div from "@jumbo/shared/Div";
import {CircularProgress} from "@mui/material";
import {createBrowserHistory} from "history";
import {Auth0Provider} from "@auth0/auth0-react";
import {env} from "./env";

const history = createBrowserHistory();
const onRedirectCallback = (appState) => {
    history.push(
        appState && appState.returnTo ? appState.returnTo : window.location.pathname
    );
};

ReactDOM.render(
    <React.StrictMode>
        <Suspense
            fallback={
                <Div
                    sx={{
                        display: 'flex',
                        minWidth: 0,
                        alignItems: 'center',
                        alignContent: 'center',
                        height: '100%',
                    }}
                >
                    <CircularProgress sx={{m: '-40px auto 0'}}/>
                </Div>
            }
        >
            <Auth0Provider
                domain={env.REACT_APP_AUTH0_DOMAIN}
                clientId={env.REACT_APP_AUTH0_CLIENT_ID}
                useRefreshTokens={true}
                onRedirectCallback={onRedirectCallback}
                authorizationParams={{
                    redirect_uri: window.location.origin,
                    ...(env.REACT_APP_AUTH0_AUDIENCE ? {audience: env.REACT_APP_AUTH0_AUDIENCE} : null),
                }}
            >
                <App/>
            </Auth0Provider>
        </Suspense>
    </React.StrictMode>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
