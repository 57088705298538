import React, {useEffect, useState} from 'react';
import {Card, CardContent, InputLabel, Select, Typography} from "@mui/material";
import FormControl from "@mui/material/FormControl";
import Box from "@mui/material/Box";
import Div from "@jumbo/shared/Div";
import Button from "@mui/material/Button";
import {useTranslation} from "react-i18next";
import {useQuery} from "@tanstack/react-query";
import axios from "../../services/config";
import MenuItem from "@mui/material/MenuItem";
import {useSaveSettings} from "../../hooks/useUserSettings";
import useJumboAuth from "@jumbo/hooks/useJumboAuth";

const Settings = () => {

    const {upn} = useJumboAuth();

    const {
        data: userSettings,
        isLoading: userSettingsIsLoading,
        status
    } = useQuery(['userSettings'], () => axios.get(`/settings/${upn}`));

    const {
        data: defaultZoneId,
        isLoading: defaultZoneIdIsLoading
    } = useQuery(['defaultZoneId'], () => axios.get("/settings/getDefaultZoneId"));
    const {
        data: zoneIds,
        isLoading: zoneIdsIsLoading
    } = useQuery(['zoneIds'], () => axios.get("/settings/getAllZoneIds"));

    const {
        data: currencies,
        isLoading: currenciesIsLoading
    } = useQuery(['currencies'], () => axios.get("/settings/getAllCurrencies"));

    const {mutate: saveSettings} = useSaveSettings();

    const [settings, setSettings] = useState();
    const {t} = useTranslation();

    useEffect(() => {
        if (status === 'success') {
            setSettings(userSettings?.data);
        }
    }, [status, userSettings]);

    if (currenciesIsLoading || userSettingsIsLoading || defaultZoneIdIsLoading || zoneIdsIsLoading || settings === undefined) {
        return <>Loading....</>
    }

    const handleSaveClicked = (zoneId, settings) => {
        console.log('CLiclci', ({zoneId, settings}));
        console.log({zoneId, settings})
        saveSettings(zoneId, settings);
    }

    return (
        <React.Fragment>
            <Typography variant="h1" mb={3}>{t('pages.title.settings')}</Typography>
            <Card sx={{display: 'flex', mb: 3.5}}>
                <Div sx={{display: 'flex', flexDirection: 'column', flex: '1'}}>
                    <CardContent>
                        <Box component="form"
                             sx={{
                                 mx: -1,

                                 '& .MuiFormControl-root:not(.MuiTextField-root)': {
                                     p: 1,
                                     mb: 2,
                                     width: {xs: '100%', sm: '50%'}
                                 },

                                 '& .MuiFormControl-root.MuiFormControl-fluid': {
                                     width: '100%'
                                 }
                             }}
                             autoComplete="off"
                        >
                            <FormControl>
                                <InputLabel id="zone-id-label">Currency</InputLabel>
                                <Select
                                    labelId="zone-id-label"
                                    value={settings.currency}
                                    label="Zone"
                                    onChange={event => setSettings({...settings, currency: event.target.value})}
                                >
                                    {
                                        currencies?.data.map((currency, idx) => {
                                            return (
                                                <MenuItem key={idx} value={currency}>{currency}</MenuItem>
                                            )
                                        })
                                    }
                                </Select>
                            </FormControl>
                            <FormControl>
                                <InputLabel id="zone-id-label">Timezone</InputLabel>
                                <Select
                                    labelId="zone-id-label"
                                    value={settings.zoneId}
                                    label="Zone"
                                    onChange={event => setSettings({...settings, zoneId: event.target.value})}
                                >
                                    {
                                        zoneIds?.data.map((zoneId, idx) => {
                                            return (
                                                <MenuItem key={idx} value={zoneId}>{zoneId}</MenuItem>
                                            )
                                        })
                                    }
                                </Select>
                            </FormControl>
                            <Div sx={{mx: 1, alignItems: 'right'}}>
                                <Button
                                    variant={"contained"}
                                    onClick={handleSaveClicked.bind(this)}
                                >
                                    Save
                                </Button>
                            </Div>
                        </Box>
                    </CardContent>
                </Div>
            </Card>
        </React.Fragment>
    );
};

export default Settings;
