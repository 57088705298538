import axios from "./config";

const categoryService = {};

categoryService.expensesByCategory = async (budgetId, transactionType = "expense") => {
    const {data} = await axios.get(`/budget/${budgetId}/stats/byCategory/${transactionType}`, {});
    return data;
};

categoryService.getAll = async () => {
    const {data} = await axios.get("/category", {});
    return data;
};

export {categoryService};
