import React, {useState} from 'react';

export const TransactionContext = React.createContext(null);

const initialFilter = {
    categories: [],
    types: []
}

const TransactionProvider = ({children}) => {
    const [filters, setFilters] = useState(initialFilter);
    const [refreshTransactionList, setRefreshTransactionList] = useState(false);

    console.log("filters", filters);
    const values = React.useMemo(() => {
        return {
            filters,
            setFilters,
            refreshTransactionList,
            setRefreshTransactionList
        };
    }, [filters, refreshTransactionList]);

    return (
        <TransactionContext.Provider value={values}>
            {children}
        </TransactionContext.Provider>
    );
};

export const useTransaction = () => {
    return React.useContext(TransactionContext);
};

export default TransactionProvider;
