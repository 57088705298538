import React from "react";
import GraphicEqIcon from '@mui/icons-material/GraphicEq';

const menus = [
    {
        label: 'sidebar.menu.home',
        type: "section",
        children: [
            {
                uri: "/events",
                label: 'sidebar.menuItem.events',
                type: "nav-item",
                icon: <GraphicEqIcon sx={{fontSize: 20}}/>
            },
        ]
    }
];

export default menus;
