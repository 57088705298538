import {Backdrop, CircularProgress} from "@mui/material";
import React from "react";

const Loading = ({open = true, color = '#fff'}) => {
    return (
        <Backdrop
            sx={{color: color, zIndex: (theme) => theme.zIndex.drawer + 1}}
            open={open}
        >
            <CircularProgress color="primary"/>
        </Backdrop>
    )
}

export default Loading;