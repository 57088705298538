import {LAYOUT_CONTAINER_STYLES} from "@jumbo/utils/constants/layout";
import LAYOUT_NAMES from "../layouts/layouts";
import axios from "../services/config";
import {mainTheme} from "../themes/main/theme3";
import {headerTheme} from "../themes/header/theme4";
import {sidebarTheme} from "../themes/sidebar/theme1";
import {footerTheme} from "../themes/footer/theme1";
import {createJumboTheme} from "@jumbo/utils";

const mainColor = '#26324d';
const config = {
    defaultLayout: LAYOUT_NAMES.VERTICAL_DEFAULT,
    containerStyle: LAYOUT_CONTAINER_STYLES.FLUID,
    theme: createJumboTheme(
        mainTheme,
        {...headerTheme},
        {
            ...sidebarTheme,
            overlay: {
                bgColor: mainColor
            }
        },
        {...footerTheme.background, ...mainTheme.palette, ...mainTheme.typography,}
    ),
    authSetting: {
        axiosObject: axios,
        fallbackPath: "/events",
        getAuthUserService: async () => {
            // if (keycloak?.authenticated) {
            //     return keycloak.loadUserInfo();
            // }
            console.log('vini isit.')
            return {};
        },
        // redirectNotAuthenticatedPath: "/"
    },

};

export {config};
