import {TrendingDownOutlined, TrendingUpOutlined} from "@mui/icons-material";
import React from "react";

const TransactionTypeIcon = ({type}) => {

    return (
        type === "revenue" ?
            <TrendingUpOutlined fontSize="small" color={"success"} sx={{ml: .3}}/>
            : <TrendingDownOutlined fontSize="small" color={"error"} sx={{ml: .3}}/>
    )
}

export default TransactionTypeIcon;