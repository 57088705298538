import axios from "./config";

const eventService = {};

eventService.create = async ({event}) => {
    const {data} = await axios.put('/event', event);
    return data;
};

export {eventService};
