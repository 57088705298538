import {useMutation, useQuery} from "@tanstack/react-query";

import axios from 'axios';

const fetchUserSettings = (userId) => {
    return () => axios.get(`/settings/${userId}`);
}

const saveUserSettings = (userId, settings) => {
    return () => axios.post(`/settings/${'userId'}`, settings);
}

export const useUserSettings = (onSuccess, onError) => {
    return useQuery(['userSettings', 'yoyo'], fetchUserSettings, {
        onSuccess,
        onError
    });
}

export const useSaveSettings = ({userId}) => {
    return useMutation(saveUserSettings(userId, {settings: {}}));
}
