import React from 'react';
import {useField} from "formik";
import {FormLabel, Radio, RadioGroup} from "@mui/material";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";

//todo: to see how to define prop-types for this component

const JumboRadioGroupField = (props) => {
    const [field, meta] = useField(props);
    const errorText = meta.error && meta.touched ? meta.error : '';

    const ariaLabelledBy = `radio-buttons-group-label-${props.label}`;
    return (

        <FormControl>
            <FormLabel id={ariaLabelledBy}>{props.label}</FormLabel>
            <RadioGroup
                row
                {...props}
                {...field}
                helperText={errorText}
                error={!!errorText}
                aria-labelledby={ariaLabelledBy}
            >
                {
                    props.values.map((value, idx) => {
                        return (
                            <FormControlLabel key={idx} value={value.id} control={<Radio/>} label={value.label}/>
                        )
                    })
                }
            </RadioGroup>
        </FormControl>
    );
};

export default JumboRadioGroupField;