import React, {useEffect, useState} from "react";
import {BrowserRouter} from "react-router-dom";
import {QueryClient, QueryClientProvider} from "@tanstack/react-query";
import JumboApp from "@jumbo/components/JumboApp";
import AppLayout from "./AppLayout";
import JumboTheme from "@jumbo/components/JumboTheme";
import AppRoutes from "./AppRoutes";
import {SnackbarProvider, useSnackbar} from "notistack";
import AppProvider from "./AppProvider";
import {config} from "./config/main";
import {ReactQueryDevtools} from '@tanstack/react-query-devtools';
import JumboRTL from "@jumbo/JumboRTL/JumboRTL";
import JumboAuthProvider from "@jumbo/components/JumboAuthProvider";
import JumboDialog from "@jumbo/components/JumboDialog";
import JumboDialogProvider from "@jumbo/components/JumboDialog/JumboDialogProvider";
import AppToastProvider from "@jumbo/providers/AppToastProvider";
import {useAuth0, withAuthenticationRequired} from "@auth0/auth0-react";
import Loading from "./components/Loading/Loading";
import useJumboAuth from "@jumbo/hooks/useJumboAuth";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {AdapterMoment} from "@mui/x-date-pickers/AdapterMoment";

const RQWrapper = ({children}) => {

    const {enqueueSnackbar} = useSnackbar();

    const queryClient = new QueryClient({
        defaultOptions: {
            queries: {
                refetchOnWindowFocus: false,
                onError: error => enqueueSnackbar(`Something went wrong: ${error.message}`, {
                    variant: "error",
                    autoHideDuration: 2000
                })
            },
            mutations: {
                onError: error => enqueueSnackbar(`Something went wrong: ${error.message}`, {
                    variant: "error",
                    autoHideDuration: 2000
                })
            }
        },
    });
    return (
        <>
            <QueryClientProvider client={queryClient}>
                {children}
                <ReactQueryDevtools initialIsOpen/>
            </QueryClientProvider>
        </>
    )
}

const AppWrappedWithAuthProvider = ({children}) => {

    const [initialized, setInitialized] = useState(false);
    const {isAuthenticated, getAccessTokenSilently} = useAuth0();
    const {setAuthToken} = useJumboAuth();

    useEffect(async () => {
        if (isAuthenticated && !initialized) {
            setAuthToken(await getAccessTokenSilently());
            setInitialized(true);
        }
    }, [isAuthenticated, initialized]);

    return (
        <JumboDialogProvider>
            <JumboDialog/>
            {children}
        </JumboDialogProvider>
    );
}
const App = () => {

    return (
        <LocalizationProvider dateAdapter={AdapterMoment}>
            <AppToastProvider>
                <SnackbarProvider
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}
                    maxSnack={3}>
                    <RQWrapper>
                        <BrowserRouter>
                            <AppProvider>
                                <JumboApp activeLayout={config.defaultLayout}>
                                    <JumboTheme init={config.theme}>
                                        <JumboRTL>
                                            <JumboAuthProvider>
                                                <AppWrappedWithAuthProvider>
                                                    <AppLayout>
                                                        <AppRoutes/>
                                                    </AppLayout>
                                                </AppWrappedWithAuthProvider>
                                            </JumboAuthProvider>
                                        </JumboRTL>
                                    </JumboTheme>
                                </JumboApp>
                            </AppProvider>
                        </BrowserRouter>
                    </RQWrapper>
                </SnackbarProvider>
            </AppToastProvider>
        </LocalizationProvider>
    );
}
export default withAuthenticationRequired(App, {
    onRedirecting: () => <Loading/>,
});
