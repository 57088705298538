import React, {useState} from 'react';
import JumboCardFeatured from "@jumbo/components/JumboCardFeatured";
import * as yup from "yup";
import JumboCardQuick from "@jumbo/components/JumboCardQuick";
import JumboDemoCard from "@jumbo/components/JumboDemoCard";
import JumboTextField from "@jumbo/components/JumboFormik/JumboTextField";
import Div from "@jumbo/shared/Div";
import { Button, Stack, TextField, Typography } from "@mui/material";
import { Form, Formik } from "formik";
import Save from '@mui/icons-material/Save';

const initialValues = {
    comment: ""
}

const CommentForm = ({onSave, comment}) => {

    const validationSchema = yup.object({
        comment: yup
            .string('Enter a comment')
            .required('A comment is required'),
    });

    return (
        <Formik
            validateOnChange={true}
            initialValues={comment ? {comment} : initialValues}
            enableReinitialize={true}
            validationSchema={validationSchema}
            onSubmit={onSave}
        >
        {({isSubmitting, setFieldValue, values, dirty, isValid, errors, handleChange}) => {
        return (
            <Form noValidate autoComplete="off">
                <Stack>
                    <JumboTextField
                        sx={{width: '100%'}}
                        id="outlined-multiline-static"
                        label="Comment"
                        name="comment"
                        multiline
                        rows={4}
                    />
                    <Div sx={{display: 'flex',  justifyContent: 'flex-end', alignItems: 'center', mt: 2}}>
                        <Button 
                            disabled={!(dirty && isValid)}
                            onClick={() => onSave(values.comment)}
                            variant="contained" 
                            startIcon={<Save />}>Save</Button>
                    </Div>
                </Stack>
            </Form>
        )}}
        </Formik>
    );
}

export default CommentForm;