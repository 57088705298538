import React, {useCallback, useEffect, useState} from "react";
import {useQuery, useQueryClient} from "@tanstack/react-query";
import axios from "../../services/config";
import {Card, CardContent, Grid, Typography} from "@mui/material";
import {formatCash} from "../../utils/appHelpers";
import {AttachMoney, SavingsSharp, TrendingDownOutlined, TrendingUpOutlined} from "@mui/icons-material";
import CardIconText from "@jumbo/shared/CardIconText";
import Div from "@jumbo/shared/Div";

import {styled} from '@mui/material/styles';
import LinearProgress from '@mui/material/LinearProgress';
import {useTransaction} from "../../providers/TransactionProvider";

const BudgetStatusGage = ({limit, totalExpenses}) => {

    const calculatedPercentage = useCallback((limit, totalExpenses) => {
        return Math.round((totalExpenses / limit) * 100);
    }, [limit, totalExpenses])

    const determineColor = (percentage) => {
        let color = "primary"
        if (percentage >= 60 && percentage <= 80) {
            color = "warning";
        } else if (percentage > 80) {
            color = "error";
        }
        return color;
    }

    const percentage = calculatedPercentage(limit, totalExpenses);

    const BorderLinearProgress = styled(LinearProgress)(({theme}) => ({
        height: 20,
        borderRadius: 5,
    }));

    return (
        <React.Fragment>
            <Card>
                <CardContent>
                    <Div sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2}}>
                        <Typography variant={"h4"} color={"text.secondary"}>Budget Limit Threshold</Typography>
                        <Div sx={{display: 'flex', alignItems: 'center'}}>
                            <Typography
                                color={determineColor(percentage)}
                                variant="h5"
                                sx={{
                                    display: {xs: 'none', sm: 'block'},
                                    mb: 0,
                                    mr: 2,
                                }}
                            >
                                {`$${Intl.NumberFormat('fr-CA').format(totalExpenses)} / $${Intl.NumberFormat('fr-CA').format(limit)}`}
                            </Typography>
                        </Div>
                    </Div>
                    <Div sx={{display: 'flex', alignItems: 'center'}}>
                        <Div sx={{width: '100%', mr: 1}}>
                            <BorderLinearProgress
                                color={determineColor(percentage)}
                                variant={"determinate"}
                                value={percentage}/>
                        </Div>
                        <Div sx={{minWidth: 35}}>
                            <Typography
                                variant="body2"
                                color={determineColor(percentage)}
                            >{`${percentage}%`}</Typography>
                        </Div>
                    </Div>
                </CardContent>
            </Card>
            {/*</Box>*/}
        </React.Fragment>
    );
};

const StatsItem = ({title, color, icon, value}) => {
    return (
        <CardIconText
            icon={icon}
            title={<Typography variant={"h4"}
                               color={color ? color : "secondary.main"}>{value}</Typography>}
            subTitle={<Typography variant={"h6"}
                                  color={"text.secondary"}>{title}</Typography>}
            color={color ? color : "secondary.main"}
            disableHoverEffect={true}
            hideArrow={true}
            variant={"contained"}
        />
    );
}

const initialBannerStats = {
    limit: 0,
    revenues: 0,
    expenses: 0,
    profit: 0
}
const BudgetNumbersBanner = ({budgetId}) => {

    const isBudgetBusted = true;
    const queryClient = useQueryClient();
    const [bannerStats, setBannerStats] = useState(initialBannerStats);
    const {refreshTransactionList} = useTransaction();

    const fetchBudgetStats = (budgetId) => axios.get(`/budget/${budgetId}/stats`);

    const {refetch} = useQuery(['budgetStats', budgetId], () => fetchBudgetStats(budgetId), {
        enabled: !!budgetId,
        select: (data) => {
            return data?.data;
        },
        onSuccess: data => {
            setBannerStats({
                limit: data.limit,
                revenues: data.revenues,
                expenses: data.expenses,
                profit: data.profit,
            })
        }
    });

    useEffect(async () => {
        await queryClient.invalidateQueries(['budgetStats', budgetId]);
        await refetch();
    }, [refreshTransactionList]);

    return (
        <React.Fragment>
            <Grid container spacing={3.75}>
                <Grid item xs={12} sm={6} lg={3}>
                    <StatsItem title={"Budget Limit"}
                               value={`$${formatCash(bannerStats.limit)}`}
                               color={isBudgetBusted ? "warning.main" : "primary.main"}
                               icon={<SavingsSharp fontSize="large"/>}/>
                </Grid>
                <Grid item xs={12} sm={6} lg={3}>
                    <StatsItem title={"Expenses"} color={"error.light"}
                               value={`$${formatCash(bannerStats.expenses)}`}
                               icon={<TrendingDownOutlined fontSize="large"/>}
                    />
                </Grid>
                <Grid item xs={12} sm={6} lg={3}>
                    <StatsItem title={"Revenus"} color={"success.main"}
                               value={`$${formatCash(bannerStats.revenues)}`}
                               icon={<TrendingUpOutlined fontSize="large"/>}
                    />
                </Grid>
                <Grid item xs={12} sm={6} lg={3}>
                    <StatsItem
                        bgcolor="error.main"
                        title={"Profit"}
                        color={bannerStats.profit < 0 ? "error.main" : "#9575cd"}
                        value={bannerStats.profit < 0 ? `($${formatCash(Math.round(bannerStats.profit * -1))})` : `$${formatCash(Math.round(bannerStats.profit))}`}
                        icon={<AttachMoney fontSize="large"/>}
                    />
                </Grid>
            </Grid>
            <Div sx={{
                mt: 3
            }}>
                <BudgetStatusGage limit={bannerStats.limit} totalExpenses={bannerStats.expenses}/>
            </Div>
        </React.Fragment>
    )
}

export default BudgetNumbersBanner;