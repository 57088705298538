import React, {useCallback, useEffect, useRef, useState} from "react";
import {Card} from "@mui/material";
import JumboRqList from "@jumbo/components/JumboReactQuery/JumboRqList";
import JumboListToolbar from "@jumbo/components/JumboList/components/JumboListToolbar";
import TransactionListingItem from "./TransactionListingItem";
import axios from "../../services/config";
import CategorySelect from "../../components/filters/CateogrySelect";
import {useTransaction} from "../../providers/TransactionProvider";
import Stack from "@mui/material/Stack";
import Divider from "@mui/material/Divider";
import TransactionTypeSelect from "../../components/filters/TransactionTypeSelect";

const TransactionListing = ({budgetId}) => {

    console.log("budgetId in listing", budgetId);

    const {filters, setFilters, refreshTransactionList, setRefreshTransactionList} = useTransaction();
    const listRef = useRef();
    const [queryOptions, setQueryOptions] = useState({
        queryKey: `/transactions/${budgetId}`,
        queryParams: {limit: 8, page: 0, q: ""},
        countKey: "data.totalElements",
        dataKey: "data.content"
    });

    const handleOnChange = (keywords) => {
        console.log("keywords", keywords);
        setQueryOptions(state => ({
            ...state,
            queryParams: {
                ...state.queryParams,
                q: keywords
            }
        }));
    };

    useEffect(() => {
        if (listRef?.current && refreshTransactionList) {
            listRef.current.refresh();
            setRefreshTransactionList(false);
        }
    }, [refreshTransactionList])
    const renderTransactionItem = useCallback((transaction) => {
        return <TransactionListingItem item={transaction} budgetId={budgetId}/>
    }, []);

    return (
        <JumboRqList
            ref={listRef}
            multiSelectOptions={[]}
            wrapperComponent={Card}
            queryOptions={queryOptions}
            primaryKey={"id"}
            service={(params) => {
                return axios.post(`transactions/${budgetId}?page=${params.queryKey[1].page}&size=${params.queryKey[1].limit}`, {
                    ...filters
                })
            }}
            renderItem={renderTransactionItem}
            itemsPerPage={8}
            itemsPerPageOptions={[8, 12, 15]}
            componentElement={'div'}
            wrapperSx={{
                flex: 1,
                display: 'flex',
                flexDirection: 'column'
            }}
            toolbar={
                <JumboListToolbar hideItemsPerPage={false}>
                    <Stack
                        direction={"row"}
                        divider={<Divider sx={{margin: 2}}/>}
                    >
                        <CategorySelect
                            multiple={true}
                            onSelectedCategory={(event, values) => {
                                setFilters(prevState => {
                                    return {
                                        ...prevState,
                                        categories: values.map(category => category.id)
                                    }
                                });
                                setRefreshTransactionList(true);
                            }}/>
                        <TransactionTypeSelect
                            onSelectTransactionType={(event, values) => {
                                setFilters(prevState => {
                                    return {
                                        ...prevState,
                                        types: values
                                    }
                                });
                                setRefreshTransactionList(true);
                            }}/>
                    </Stack>
                </JumboListToolbar>

            }
        />
    )
}
export default TransactionListing;