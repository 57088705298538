import React, {useState} from "react";
import {Autocomplete, Box, Checkbox, TextField} from "@mui/material";
import {useQuery} from "@tanstack/react-query";
import {categoryService} from "../../services/category-service";
import TransactionCategoryChip from "../TransactionCategoryChip";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";

const CategorySelect = ({onSelectedCategory, multiple = false}) => {
    const [categories, setCategories] = useState();

    const {isLoading} = useQuery(['categories'], () => categoryService.getAll(), {
        select: (data) => data,
        onSuccess: (data) => {
            setCategories(data);
        },
        onError: (err) => {
            console.error(err)
        }
    });

    return (
        <React.Fragment>
            <Autocomplete
                size={"small"}
                multiple={multiple}
                limitTags={2}
                // fullWidth
                sx={{width: 200}}
                clearIcon={false}
                loading={isLoading}
                loadingText={"Loading..."}
                options={categories ? categories : []}
                isOptionEqualToValue={(option, value) => option.name === value.name}
                onChange={(event, value) => {
                    if (onSelectedCategory) {
                        onSelectedCategory(event, value);
                    }
                }}
                getOptionLabel={(option) => option.name}
                renderOption={(props, option, {selected}) => (
                    <Box component="li" sx={{'& > img': {mr: 2, flexShrink: 0}}} {...props}>
                        <Checkbox
                            icon={<CheckBoxOutlineBlankIcon fontSize="small"/>}
                            checkedIcon={<CheckBoxIcon fontSize="small"/>}
                            style={{marginRight: 8}}
                            checked={selected}
                        /><TransactionCategoryChip value={option.hex} item={option.name}/>
                    </Box>
                )}
                renderInput={(params) => <TextField autoWidth {...params} label="Categories"/>}
            />
        </React.Fragment>
    );
}

export default CategorySelect;