import React, {useState} from "react";
import {Button, CircularProgress, Typography} from "@mui/material";
import Grid from "@mui/material/Grid";
import {useQuery} from "@tanstack/react-query";
import axios from "../../services/config";
import Div from "@jumbo/shared/Div";
import EventItemCard from "./EventItemCard";
import {Add} from "@mui/icons-material";
import {Link as RouterLink} from "react-router-dom";

const EventListDashboard = () => {

    const [events, setEvents] = useState([]);
    useQuery(['events'], () => axios.get("/event"), {
        onSuccess: data => setEvents(data?.data.events)
    });

    return (
        <React.Fragment>
            <Typography variant={'h2'} mb={3}>Events</Typography>
            <Grid container spacing={3.75}>
                <Grid item md={12}>
                    <Button
                        component={RouterLink}
                        to={'/events/create'}
                        variant="contained" startIcon={<Add/>}>
                        Create
                    </Button>
                </Grid>
                {
                    events.map((event, index) => (
                        <Grid item key={index} xs={12} sm={6} lg={4}>
                            <EventItemCard item={event}/>
                        </Grid>
                    ))
                }
            </Grid>
        </React.Fragment>
    )
}
const Loading = () => {
    return (
        <Div
            sx={{
                display: 'flex',
                minWidth: 0,
                alignItems: 'center',
                alignContent: 'center',
                height: '100%',
            }}
        >
            <CircularProgress sx={{m: '-40px auto 0'}}/>
        </Div>
    )
}

export default EventListDashboard;
