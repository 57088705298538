import baseAxios from "axios";
import {env} from "../../env";

const axios = baseAxios.create({
    baseURL: `${env.REACT_APP_API_URL}/api/v1`,
    headers: {
        'Content-Type': 'application/json'
    }
});

//todo: define interceptors and other configuration like baseURL, headers etc. here
axios.defaults.auth = {
    username: "user",
    password: "user"
};

export default axios;