import Div from "@jumbo/shared/Div";
import {Cell, Pie, PieChart, ResponsiveContainer, Sector} from "recharts";
import React, {useEffect, useState} from "react";
import {useQuery, useQueryClient} from "@tanstack/react-query";
import {categoryService} from "../../services/category-service";
import {useTransaction} from "../../providers/TransactionProvider";
import JumboCardQuick from "@jumbo/components/JumboCardQuick";

const ExpenseByCategory = ({budgetId}) => {

    const renderActiveShape = props => {
        const RADIAN = Math.PI / 180;
        const {cx, cy, midAngle, innerRadius, outerRadius, startAngle, endAngle, fill, payload, percent, value} = props;
        const sin = Math.sin(-RADIAN * midAngle);
        const cos = Math.cos(-RADIAN * midAngle);
        const sx = cx + (outerRadius + 10) * cos;
        const sy = cy + (outerRadius + 10) * sin;
        const mx = cx + (outerRadius + 30) * cos;
        const my = cy + (outerRadius + 30) * sin;
        const ex = mx + (cos >= 0 ? 1 : -1) * 22;
        const ey = my;
        const textAnchor = cos >= 0 ? 'start' : 'end';

        return (
            <g>
                <text x={cx} y={cy} dy={8} textAnchor="middle" fill={fill}>
                    {payload.name}
                </text>
                <Sector
                    cx={cx}
                    cy={cy}
                    innerRadius={innerRadius}
                    outerRadius={outerRadius}
                    startAngle={startAngle}
                    endAngle={endAngle}
                    fill={fill}
                />
                <Sector
                    cx={cx}
                    cy={cy}
                    startAngle={startAngle}
                    endAngle={endAngle}
                    innerRadius={outerRadius + 6}
                    outerRadius={outerRadius + 10}
                    fill={fill}
                />
                <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} pathLength={10} stroke={fill} fill="none"/>
                <circle cx={ex} cy={ey} r={5} fill={fill} stroke="none"/>
                <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} textAnchor={textAnchor}
                      fill="#333">{`${payload.name} ${(percent * 100).toFixed(0)}%`}</text>
                <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} dy={18} textAnchor={textAnchor} fill="#999">
                    {Intl.NumberFormat('fr-CA').format(payload.payload.sum)}
                </text>
            </g>
        );
    };

    const queryClient = useQueryClient();

    const {refreshTransactionList} = useTransaction();
    const [pieData, setPieData] = useState([]);
    const [activeIndex, setActiveIndex] = useState(0);
    const queryKey = ['expensesByCategory', budgetId];


    useEffect(async () => {
        await queryClient.invalidateQueries(queryKey);
        await refetch();
    }, [refreshTransactionList])

    const {refetch} = useQuery(queryKey, () => categoryService.expensesByCategory(budgetId, "expense"), {
        onSuccess: data => {
            const arrSum = Object.entries(data).map(value => value[1].total);
            const highestIndex = arrSum.indexOf(Math.max(...arrSum));
            setActiveIndex(highestIndex);

            const total = Object.entries(data).map(p => p[1].total).reduce((previous, current) => previous + current);

            setPieData(Object.entries(data).map(p => {
                const value = Math.round((p[1].total / total) * 100);
                return {
                    name: p[1].name,
                    value,
                    color: p[1].color,
                    sum: p[1].total
                }
            }));
        }
    })

    return (
        <Div>
            <JumboCardQuick
                title={"widgets.title.ticketStatus"}
                // sx={{textAlign: 'center'}}
                headerSx={{
                    borderColor: 'divider',
                    bgcolor: 'background.paper',
                }}
                // wrapperSx={{pt: 0, backgroundColor: 'background.paper'}}
                // wrapperSx={{
                //     pt: 2,
                //     '&:last-child': {
                //         pb: 6.5
                //     }
                // }}
            >
                <ResponsiveContainer minHeight={500}>
                    <PieChart>
                        <defs>
                            <filter id="f1" x="0" y="0" width="150%" height="150%">
                                <feGaussianBlur in="SourceAlpha" stdDeviation="4"/>
                                <feOffset dx="2" dy="4"/>
                                <feMerge>
                                    <feMergeNode/>
                                    <feMergeNode in="SourceGraphic"/>
                                </feMerge>
                            </filter>
                        </defs>
                        <Pie
                            data={pieData}
                            color="#000000"
                            dataKey="value"
                            nameKey="name"
                            cx="50%"
                            cy="50%"
                            // outerRadius={120}
                            innerRadius={80}
                            // outerRadius={80}
                            isAnimationActive={true}
                            fill="#8884d8"
                            strokeWidth={3}
                            activeIndex={activeIndex}
                            activeShape={renderActiveShape}
                            onMouseEnter={(data, index) => setActiveIndex(index)}
                        >
                            {pieData.map((entry, index) => (
                                <Cell key={`cell-${index}`}
                                      fill={entry.color}
                                      strokeWidth={1}
                                />
                            ))}

                        </Pie>
                    </PieChart>
                </ResponsiveContainer>
            </JumboCardQuick>
        </Div>
    )
}

export default ExpenseByCategory;