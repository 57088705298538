import * as React from "react";
import {TextField} from "@mui/material";
import {Controller, get, useFormContext} from "react-hook-form";

export const ControlledTextField = ({...props}) => {
    const {control, formState} = useFormContext();
    const error = get(formState.errors, props.name);
    const {value} = props;

    return (
        <Controller
            {...props}
            control={control}
            defaultValue={props.defaultValue}
            render={({field}) => (
                <TextField
                    {...props}
                    {...field}
                    error={!!error}
                    fullWidth
                />
            )}
        />
    );
};

export default ControlledTextField;
