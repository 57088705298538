import React, {useEffect, useState} from 'react';
import Chip from "@mui/material/Chip";

const TransactionCategoryChip = ({item, value}) => {

    const [name, setName] = useState("");
    const [colorHex, setColorHex] = useState("");

    useEffect(() => {
        setName(item);
        setColorHex(value);
    }, [name, colorHex]);

    const categoryChipStyle = {
        backgroundColor: colorHex,
        color: "#FFFFFF"
    }

    return (
        <React.Fragment>
            <Chip
                sx={{}}
                size={"small"}
                title={name}
                label={name}
                style={categoryChipStyle}
            />
        </React.Fragment>
    );
}

export default TransactionCategoryChip;