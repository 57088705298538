import {useMutation, useQuery} from "@tanstack/react-query";
import React, {useState} from 'react';
import * as yup from "yup";
import {Field, FieldArray, Form, Formik} from "formik";
import LoadingButton from "@mui/lab/LoadingButton";
import JumboTextField from "@jumbo/components/JumboFormik/JumboTextField";
import {transactionService} from "../../services/transaction-service";
import CustomizedSelectForFormik from "../../components/CustomizedSelectForFormik";
import MenuItem from "@mui/material/MenuItem";
import {categoryService} from "../../services/category-service";
import JumboRadioGroupField from "../../components/controls/JumboRadioGroupField";
import SaveIcon from '@mui/icons-material/Save';
import {
    Autocomplete,
    Badge,
    Box,
    Button,
    Container,
    Divider,
    Grid,
    IconButton,
    InputAdornment,
    List,
    ListItem,
    ListItemButton,
    ListItemText,
    Stack,
    TextField,
    Typography,
} from "@mui/material";
import TransactionCategoryChip from "app/components/TransactionCategoryChip";
import Div from "@jumbo/shared/Div";
import DeleteIcon from "@mui/icons-material/Delete";
import axios from "axios";
import moment from "moment";

import {InsertCommentOutlined} from "@mui/icons-material";
import CommentForm from "app/components/comments/CommentForm";
import {useJumboDialog} from "@jumbo/components/JumboDialog/hooks/useJumboDialog";


export const TransactionType = {
    Expense: 0,
    Revenue: 1,
}

const initialValues = {
    id: "",
    name: "",
    category: "63238af4385f542c4981a6dc",
    type: TransactionType.Expense,
    currency: 'CAD',
    amount: 0.0,
    comments: [
        "Generated 1 paragraph, 5 words, 27 bytes of Lorem Ipsum",
        "Generated 1 paragraph, 5 words, 27 bytes of Lorem Ipsum",
        "Generated 1 paragraph, 5 words, 27 bytes of Lorem Ipsum",
        "Generated 1 paragraph, 5 words, 27 bytes of Lorem Ipsum",
        "Generated 1 paragraph, 5 words, 27 bytes of Lorem Ipsum",
        "Generated 1 paragraph, 5 words, 27 bytes of Lorem Ipsum",
        "Generated 1 paragraph, 5 words, 27 bytes of Lorem Ipsum",
        "Generated 1 paragraph, 5 words, 27 bytes of Lorem Ipsum",
    ]
}

const TransactionForm = ({budgetId, transaction, onCloseDialog}) => {

    const [categories, setCategories] = useState([]);
    const [currencies, setCurrencies] = useState([]);

    const {showDialog, hideDialog} = useJumboDialog();

    const handleCommentForm = React.useCallback(() => {
        hideDialog();
    }, [hideDialog]);


    const showUpdateCommentDialog = React.useCallback((replace, index, value) => {
        showDialog({
            title: <Typography variant={'body1'} mb={2} align={"center"}>
                <Typography variant={'h1'}>Update Comment</Typography>
                <Divider sx={{mb: 2}}/>
            </Typography>,
            content: <CommentForm
                comment={value}
                onSave={(comment) => {
                    console.log("comment saved is", comment);
                    replace(index, comment);
                    handleCommentForm();
                }}/>
        });
    }, [handleCommentForm, showDialog]);

    const showAddCommentDialog = React.useCallback((push) => {
        showDialog({
            title: <Typography variant={'body1'} mb={2} align={"center"}>
                <Typography variant={'h1'}>Add New Comment</Typography>
                <Divider sx={{mb: 2}}/>
            </Typography>,
            content: <CommentForm

                onSave={(comment) => {
                    console.log("comment saved is", comment);
                    push(comment);
                    handleCommentForm();
                }}/>
        });
    }, [handleCommentForm, showDialog]);

    const {isLoading} = useQuery(['categories'], categoryService.getAll, {
        onSuccess: (data) => {
            setCategories(data);
        }
    })

    useQuery(['currencies'], () => axios.get("https://restcountries.com/v3.1/all", {}).then(data => data.data), {
        select: (data) => data
            .filter(country => !!country.currencies)
            .map(country => {
                const currency = Object.keys(country.currencies)[0];
                const {symbol} = country.currencies[Object.keys(country.currencies)[0]];
                return {
                    'countryCode': country.cca2,
                    'label': currency,
                    symbol,
                    flag: country.flag

                }
            })
            .filter((item, i, ar) => ar.indexOf(item) === i),
        onSuccess: (data) => {
            setCurrencies(data);
        },
        onError: (err) => {
            console.error(err)
        }
    })


    // const {enqueueSnackbar, closeSnackbar} = useSnackbar();
    const validationSchema = yup.object({
        name: yup
            .string('Enter the transaction name')
            .required('Transaction name is required'),
        amount: yup
            .string('Enter the transaction amount')
            .required('Transaction amount is required'),
        type: yup
            .string('Select the transaction type')
            .required('Transaction type is required'),
        currency: yup
            .string('Select a currency')
            .required('Currency is required'),
    });


    const saveMutation = useMutation(({
                                          budgetId,
                                          transaction
                                      }) => {
        console.log("TEST TEST TEST TEST", budgetId);
        return transactionService.save(budgetId, transaction);
    }, {
        onSuccess: () => onCloseDialog(transaction)
    })

    const onTransactionSave = (transaction, {setSubmitting}) => {
        setTimeout(() => {
            console.log(JSON.stringify(transaction, null, 2), budgetId);
            setSubmitting(true);
            saveMutation.mutate({budgetId, transaction});
            setSubmitting(false);
        }, 400);
    };

    if (isLoading) return <>Loading categories...</>

    const radioValues = [
        {
            id: TransactionType.Expense,
            label: "Revenue"
        },
        {
            id: TransactionType.Revenue,
            label: "Expense"
        }];

    return (
        <Formik
            validateOnChange={false}
            initialValues={transaction?.id ? transaction : initialValues}
            enableReinitialize={true}
            validationSchema={validationSchema}
            onSubmit={onTransactionSave}
        >
            {({isSubmitting, setFieldValue, values, dirty, isValid, errors, handleChange}) => {
                return (
                    <Form noValidate autoComplete="off">
                        <Grid
                            container
                            direction="row"
                            justifyContent="center"
                            alignItems="center"
                        >

                            <Container>
                                <Grid container spacing={2}>
                                    <Grid item xs>
                                        <JumboRadioGroupField
                                            label={"Transaction type"}
                                            name="type"
                                            values={radioValues}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Field
                                            name="category"
                                            label="category"
                                            component={CustomizedSelectForFormik}
                                        >
                                            {
                                                categories.map((category, idx) => {
                                                    return (
                                                        <MenuItem key={idx} value={category.id}>
                                                            {category.name}
                                                            <TransactionCategoryChip
                                                                item={category?.hex.toUpperCase()}
                                                                value={category?.hex}
                                                            />
                                                        </MenuItem>
                                                    );
                                                })
                                            }
                                        </Field>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <JumboTextField fullWidth size="small" variant="outlined" name="name"
                                                        label="Name"/>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <JumboTextField
                                            size="small"
                                            name="amount"
                                            type="number"
                                            label="Amount"
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">{values.currency} $</InputAdornment>
                                                )
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Autocomplete
                                            value={values?.currency}
                                            autoSelect={true}
                                            size="small"
                                            disableClearable={true}
                                            autoComplete={false}
                                            options={currencies}
                                            autoHighlight
                                            isOptionEqualToValue={(option, value) => option.label === value}
                                            onChange={(event, value) => {
                                                setFieldValue("currency", value.code);
                                            }}
                                            getOptionLabel={(option) => `${option.label} ${option.flag}ddfd`}
                                            renderOption={(props, option) => (
                                                <Box component="li" sx={{'& > img': {mr: 2, flexShrink: 0}}} {...props}>
                                                    <img
                                                        loading="lazy"
                                                        width="20"
                                                        src={`https://flagcdn.com/w20/${option.countryCode.toLowerCase()}.png`}
                                                        srcSet={`https://flagcdn.com/w40/${option.countryCode.toLowerCase()}.png 2x`}
                                                        alt=""
                                                    />
                                                    {option.label.toUpperCase()} - {option.symbol}
                                                </Box>
                                            )}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    onChange={handleChange}
                                                    label="Currency"
                                                    value={values?.currency}
                                                    name="currency"
                                                    inputProps={{
                                                        ...params.inputProps,
                                                        // autoComplete: 'new-password', // disable autocomplete and autofill
                                                    }}
                                                />
                                            )}
                                        />
                                    </Grid>

                                    <FieldArray name="comments">
                                        {({push, remove, replace}) => (
                                            <>
                                                <Grid item xs>
                                                    <Stack>
                                                        <Div sx={{
                                                            display: 'flex',
                                                            justifyContent: 'space-between',
                                                            alignItems: 'center',
                                                            border: '1 px solid,',
                                                            p: 1
                                                        }}>
                                                            <Typography
                                                                variant={"h1"}
                                                                color={"text.primary"}
                                                            >
                                                                <Badge badgeContent={values.comments.length}
                                                                       variant="standard"
                                                                       color="primary">Comments</Badge>
                                                            </Typography>
                                                            <Button
                                                                onClick={() => showAddCommentDialog(push)}
                                                                variant="contained"
                                                                startIcon={<InsertCommentOutlined/>}>
                                                                Add Comment
                                                            </Button>
                                                        </Div>
                                                        <Divider/>
                                                    </Stack>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <List>
                                                        {values.comments.map((comment, idx) => {
                                                            return (
                                                                <>
                                                                    <ListItem
                                                                        divider
                                                                        key={idx}
                                                                        secondaryAction={
                                                                            <IconButton aria-label="comment">
                                                                                <DeleteIcon onClick={() => remove(idx)}
                                                                                            color={"error"}/>
                                                                            </IconButton>
                                                                        }
                                                                    >
                                                                        <ListItemButton
                                                                            onClick={() => showUpdateCommentDialog(replace, idx, comment)}
                                                                        >
                                                                            <ListItemText
                                                                                key={idx}
                                                                                primary={
                                                                                    <>
                                                                                        <Typography variant="caption">
                                                                                            {moment().format('MMMM Do YYYY, h:mm:ss a')}
                                                                                        </Typography>
                                                                                    </>
                                                                                }
                                                                                secondary={
                                                                                    <>
                                                                                        <Typography variant="h6" noWrap>
                                                                                            {comment}
                                                                                        </Typography>
                                                                                    </>
                                                                                }
                                                                            >
                                                                            </ListItemText>
                                                                        </ListItemButton>
                                                                    </ListItem>

                                                                </>
                                                            );
                                                        })}
                                                    </List>
                                                </Grid>
                                            </>
                                        )}

                                    </FieldArray>

                                    <Grid item xs={12}>
                                        <LoadingButton
                                            disabled={!(dirty && isValid)}
                                            fullWidth
                                            type="submit"
                                            variant="contained"
                                            size="large"

                                            loading={isSubmitting || saveMutation.isLoading}
                                            endIcon={<SaveIcon/>}
                                        >
                                            Save
                                        </LoadingButton>
                                    </Grid>
                                </Grid>
                            </Container>
                        </Grid>
                    </Form>
                )
            }}
        </Formik>
    );
}

export default TransactionForm