import axios from "./config";

const transactionService = {};

transactionService.getTransactions = async ({queryKey}) => {
    const {page, limit, queryParams} = queryKey[queryKey.length - 1];
    const {data} = await axios.get("/transactions", {
        params: {
            page: page,
            limit: limit,
            ...queryParams,
        }
    });
    return data;
};

transactionService.getLabels = async () => {
    const {data} = await axios.get("/transactions/labels");
    return data;
};

transactionService.lock = async (id) => {
    const {data} = await axios.post(`/transactions/${id}/lock`);
    return data;
};

transactionService.duplicate = async (id) => {
    const {data} = await axios.post(`/transactions/${id}/duplicate`);
    return data;
};

transactionService.unlock = async (id) => {
    const {data} = await axios.post(`/transactions/${id}/unlock`);
    return data;
};

transactionService.bulkLock = async (ids) => {
    const {data} = await axios.post(`/transactions/bulk-lock`, {
        ids: ids
    });
    return data;
};

transactionService.save = async (budgetId, transaction) => {
    const {data} = await axios.put(`/transactions/${budgetId}`, transaction);
    return data;
};

transactionService.deleteTransaction = async (budgetId, transactionId) => {
    const {data} = await axios.delete(`/transactions/${transactionId}/budget/${budgetId}`, {});
    return data;
};

transactionService.deleteMultiple = async (selectedIDs) => {
    const {data} = await axios.put("/transactions/bulk-delete", {
        contactIDs: selectedIDs
    });
    return data;
};

transactionService.addLabel = async (label) => {
    const {data} = await axios.post("/transactions/add-label", label);
    return data;
};

transactionService.updateLabel = async (label) => {
    const {data} = await axios.put("/transactions/update-label", label);
    return data;
};

transactionService.deleteLabel = async (label) => {
    const {data} = await axios.delete("/transactions/delete-label", {
        params: {id: label.id}
    });
    return data;
};

transactionService.assignLabel = async (params) => {
    const {data} = await axios.put("/transactions/assign-label", {
        contactIDs: params?.contactIDs ?? [],
        labelIDs: params?.labelIDs ?? []
    });
    return data;
};

export {transactionService};
