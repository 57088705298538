import React from "react";
import {Autocomplete, Box, Checkbox, TextField, Typography} from "@mui/material";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import TransactionTypeIcon from "../TransactionTypeIcon";

const TransactionTypeSelect = ({onSelectTransactionType}) => {

    return (
        <React.Fragment>
            <Autocomplete
                size={"small"}
                multiple
                limitTags={2}
                sx={{width: 200}}
                clearIcon={false}
                options={["Expense", "Revenue"]}
                onChange={(event, value) => {
                    if (onSelectTransactionType) {
                        onSelectTransactionType(event, value);
                    }
                }}
                getOptionLabel={(option) => {
                    console.log("option", option)
                    return option;
                }}
                renderOption={(props, option, {selected}) => (
                    <Box component="li" sx={{'& > img': {mr: 2, flexShrink: 0}}} {...props}>
                        <Checkbox
                            icon={<CheckBoxOutlineBlankIcon fontSize="small"/>}
                            checkedIcon={<CheckBoxIcon fontSize="small"/>}
                            style={{marginRight: 8}}
                            checked={selected}
                        />
                        <Typography
                            variant={"body1"}
                            sx={{
                                alignItems: "center",
                                display: "flex"
                            }}
                        >
                            {option}
                            <TransactionTypeIcon type={option.toLowerCase()}/>
                        </Typography>
                    </Box>
                )}
                renderInput={(params) => <TextField autoWidth {...params} label="Type"/>}
            />
        </React.Fragment>
    );
}

export default TransactionTypeSelect;