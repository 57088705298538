import React from 'react';
import * as yup from "yup";
import {FormProvider, useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup/dist/yup";
import {Box, Container, Divider, Grid, IconButton, InputAdornment, Stack, Typography} from "@mui/material";
import {KeyboardReturn, Save} from "@mui/icons-material";
import JumboCardQuick from "@jumbo/components/JumboCardQuick";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import ControlledTextField from "../../../components/controls/ControlledTextField";
import moment from "moment";
import ControlledFiledInputField from "../../../components/controls/ControlledFiledInputField";
import {useMutation} from "@tanstack/react-query";
import {eventService} from "../../../services/event-service";

const admissionAndProjection = yup.object({
    early: yup.number().min(0).required(),
    regular: yup.number().min(0).required(),
    dayOf: yup.number().min(0).required(),
});

const validationSchema = yup.object({
    name: yup.string().required(),
    startDate: yup.date().required(),
    endDate: yup.date().required(),
    budget: yup.object({
        limitAmount: yup.number().min(0).required()
    }),
    venue: yup.object({
        name: yup.string().required(),
        address: yup.string().required(),
        capacity: yup.number().integer().min(0).required()
    }),
    // admission: admissionAndProjection,
    projection: admissionAndProjection
});

const CreateEvent = () => {

    const {t} = useTranslation('event');
    const navigate = useNavigate();
    const createMutation = useMutation(['createEvent'], eventService.create)

    const methods = useForm({
        shouldUnregister: false,
        defaultValues: {
            name: undefined,
            startDate: moment(),
            endDate: moment(),
            venue: {
                name: undefined,
                address: undefined,
                capacity: 0,
            },
            budget: {
                limitAmount: 60.50
            },
            // admission: {
            //     early: 0,
            //     regular: 0,
            //     dayOf: 0
            // },
            projection: {
                early: 0,
                regular: 0,
                dayOf: 0
            }
        },
        resolver: yupResolver(validationSchema),
        mode: "onChange"
    });

    const {
        handleSubmit,
        reset,
        formState: {isDirty}
    } = methods;

    const onSubmit = (data) => {
        console.log("data to submit", data);
        createMutation.mutate({event: data});
        // saveMutation.mutate({budgetId, transaction});
    }

    return (
        <>
            <JumboCardQuick
                // wrapperSx={{p: 0}}
                divider
                noWrapper
                title={<Typography variant={"h1"}>{t('title')}</Typography>}
                sx={{pb: 4}}
                action={
                    <Stack direction={'row'}>
                        <IconButton
                            color={'warning'}
                            title={t('global.cancel')}
                            aria-label={t('global.cancel')}
                            onClick={() => navigate(-1)}
                        >
                            <KeyboardReturn/>
                        </IconButton>
                        <IconButton
                            color="primary"
                            disabled={!isDirty}
                            title={t('global.save')}
                            aria-label={t('global.save')}
                            onClick={handleSubmit(onSubmit)}
                        >
                            <Save/>
                        </IconButton>
                    </Stack>
                }
            >
                <FormProvider {...methods}>
                    <Box
                        component="form"
                        noValidate
                        autoComplete="off"
                    >
                        <Container maxWidth={'lg'}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Typography variant={"h4"}>{t('generalInformation')}</Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <ControlledTextField
                                        label={t('name')}
                                        name={'name'}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <ControlledTextField
                                        minDate={moment()}
                                        label={t('startDate')}
                                        name={'startDate'}
                                        // value={moment()}
                                        // onChange={(newValue) => setValue(newValue)}
                                        required
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <ControlledTextField
                                        minDate={moment()}
                                        label={t('endDate')}
                                        name={'endDate'}
                                        // value={moment()}
                                        // onChange={(newValue) => setValue(newValue)}
                                        required
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <ControlledFiledInputField
                                        name="budget.limitAmount"
                                        label={t('budget.threshold')}
                                        type='number'
                                        fullWidth
                                        endAdornment={<InputAdornment position="end">$</InputAdornment>}
                                        required
                                    />
                                </Grid>
                            </Grid>
                            <Divider sx={{mt: 3, mb: 3}}/>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Typography variant={"h4"}>{t('venue.title')}</Typography>
                                </Grid>
                                <Grid item xs={12} md={5}>
                                    <ControlledTextField
                                        label={t('venue.name')}
                                        name={'venue.name'}
                                    />
                                </Grid>
                                <Grid item xs={12} md={5}>
                                    <ControlledTextField
                                        label={t('venue.address')}
                                        name={'venue.address'}
                                    />
                                </Grid>
                                <Grid item xs={12} md={2}>
                                    <ControlledTextField
                                        label={t('venue.capacity')}
                                        name={'venue.capacity'}
                                        type={'number'}
                                    />
                                </Grid>
                            </Grid>
                            <Divider sx={{mt: 3, mb: 3}}/>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Typography variant={"h4"}>{t('projection.title')}</Typography>
                                </Grid>
                            </Grid>
                        </Container>
                    </Box>
                </FormProvider>

            </JumboCardQuick>
        </>
    );
};

export default CreateEvent;