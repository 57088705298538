import React, {useState} from 'react';
import {AvatarGroup, Card, CardContent, Grid, Typography} from "@mui/material";
import {useQuery} from "@tanstack/react-query";
import {useParams} from "react-router-dom";
import axios from "../../services/config";
import Div from "@jumbo/shared/Div";
import CalendarTodayOutlinedIcon from "@mui/icons-material/CalendarTodayOutlined";
import AddIcon from '@mui/icons-material/Add';
import Avatar from "@mui/material/Avatar";
import BudgetNumbersBanner from "./BudgetNumbersBanner";
import TransactionListing from "./TransactionListing";
import Fab from "@mui/material/Fab";
import {useJumboDialog} from "@jumbo/components/JumboDialog/hooks/useJumboDialog";
import TransactionForm from "./TransactionForm";
import Divider from "@mui/material/Divider";
import {useTransaction} from "../../providers/TransactionProvider";
import ExpenseByCategory from "./ExpenseByCategory";
import Loading from "../../components/Loading/Loading";
import Stack from "@mui/material/Stack";
import moment from "moment";
import ProfitSharing from "./ProfitSharing";


const EventBudgetOverview = () => {
    const {id} = useParams();
    const {showDialog, hideDialog} = useJumboDialog();
    const {setRefreshTransactionList} = useTransaction();
    const [budgetId, setBudgetId] = useState();
    const {isLoading, data: event} = useQuery(["eventById", id], () => axios.get(`/event/${id}`), {
        enabled: !!id,
        onSuccess: data => {
            setBudgetId(data.data.budgetId);
        }
    });

    const handleTransactionAdd = React.useCallback((transaction) => {
        setRefreshTransactionList(true);
        hideDialog();
    }, [hideDialog]);

    const showAddContactDialog = React.useCallback((budgetId) => {
        showDialog({
            title: <Typography variant={'body1'} mb={2} align={"center"}>
                <Typography variant={'h1'}>Add new transaction</Typography>
                <Divider sx={{mb: 2}}/>
            </Typography>,
            content: <TransactionForm budgetId={budgetId} onCloseDialog={handleTransactionAdd}/>


        });
    }, [handleTransactionAdd, showDialog]);

    if (isLoading || !budgetId) {
        return (<Loading/>)
    }

    return (
        <React.Fragment>
            <Grid container spacing={3.5}>
                <Grid item xs={12}>
                    <Card
                        sx={{
                            mb: 2,
                        }}
                    >
                        <CardContent>
                            <Div sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                                <Div sx={{width: '50%', flexShrink: 0, p: 2}}>
                                    <Stack>
                                        <Typography variant={"h1"} gutterBottom>{event?.data.name}</Typography>
                                        <Typography fontSize={"12px"} variant={"body1"} color={"text.secondary"}>
                                            <CalendarTodayOutlinedIcon
                                                sx={{
                                                    verticalAlign: 'middle',
                                                    fontSize: '1.2rem',
                                                    mt: -.25,
                                                    mr: .25
                                                }}
                                            />
                                            {moment(event?.data.start).format("LLLL")} - {moment(event?.data.end).format("LLLL")}
                                        </Typography>
                                    </Stack>
                                </Div>
                                <AvatarGroup max={4} sx={{pr: 1}}>
                                    {
                                        event?.data.organizers.map((organizer, index) => (
                                            <Avatar
                                                title={organizer.name}
                                                src={organizer.profilePic}
                                                key={index}
                                            >
                                                {organizer.initials}
                                            </Avatar>
                                        ))
                                    }
                                </AvatarGroup>
                            </Div>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12}>
                    <BudgetNumbersBanner budgetId={budgetId}/>
                </Grid>

                <Grid item xs={12} md={7} lg={8}>
                    <TransactionListing budgetId={budgetId}/>
                </Grid>
                <Grid item xs={12} md={5} lg={4}>
                    <ExpenseByCategory budgetId={budgetId}/>
                </Grid>

                <Grid item xs={12}>
                    <ProfitSharing budgetId={budgetId} profit={-100}/>
                </Grid>
            </Grid>


            <Fab
                onClick={() => showAddContactDialog(budgetId)}
                color={"primary"}
                sx={{
                    position: 'fixed',
                    bottom: 16,
                    right: 16,
                }}
                aria-label={"Add Transaction"}
            >
                <AddIcon/>
            </Fab>
        </React.Fragment>
    )
}

export default EventBudgetOverview;