import React from 'react';
import {AvatarGroup, Button, Typography} from "@mui/material";
import Div from "@jumbo/shared/Div";
import Chip from "@mui/material/Chip";
import Avatar from "@mui/material/Avatar";
import JumboCardQuick from "@jumbo/components/JumboCardQuick";
import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";
import {formatCash} from "../../utils/appHelpers";
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import {TrendingDown} from "@mui/icons-material";
import axios from "../../services/config";
import {useQuery} from "@tanstack/react-query";
import {Link as RouterLink,} from 'react-router-dom';
import Loading from "../../components/Loading/Loading";
import moment from "moment";

const Item = ({children, sx}) => (
    <Div sx={{p: theme => theme.spacing(1, 2), ...sx}}>
        {children}
    </Div>
);

const EventItemCard = ({item}) => {

    const fetchBudgetStats = (budgetId) => axios.get(`/budget/${budgetId}/stats`);

    const {data: stats, isLoading} = useQuery(['budgetStats', item.budgetId], () => fetchBudgetStats(item.budgetId), {
        select: (data) => {
            return data?.data;
        }
    });

    const getStatusColor = (status) => {
        let color = 'success'
        if (status === "Pending") {
            color = 'warning'
        }
        return color;
    }

    if (isLoading) {
        return <><Loading/></>
    }
    const isBudgetBusted = stats.expenses > item.budgetLimit;

    return (
        <React.Fragment>
            <JumboCardQuick
                title={<Typography variant={"h5"} mb={0}>{item.title}</Typography>}
                subheader={moment(item.start).format('LLLL')}
                action={
                    <Chip color={getStatusColor(item.status)} size={"small"} label={item.status}/>
                }
                wrapperSx={{textAlign: 'center'}}
                headerSx={{
                    borderBottom: 1,
                    borderBottomColor: 'divider'
                }}
            >
                <Div sx={{
                    display: 'flex',
                    alignItems: 'center',
                    flexDirection: 'column',
                    textAlign: 'center'
                }}
                >
                    <Div sx={{mb: 1}}>
                        <AvatarGroup max={4}>
                            {
                                item.organizers.map((organizer, index) => (
                                    <Avatar title={organizer.name} src={organizer.profilePic}
                                            key={index}>{organizer.initials}</Avatar>
                                ))
                            }
                        </AvatarGroup>
                    </Div>
                    <Stack direction={"row"} alignItems={"center"} sx={{p: 0}}>
                        <Item>
                            <Typography
                                title={isBudgetBusted ? "The budget is busted" : ""}
                                color={isBudgetBusted ? "error" : "success.main"} variant={"h3"}
                                mb={.5}>{`$${formatCash(item.budgetLimit | 0)}`}</Typography>
                            {/*<Fade in={isBudgetBusted}><WarningOutlined color={"warning"}/></Fade>*/}
                            <Typography variant={"body1"} color="text.secondary">Budget Limit</Typography>
                        </Item>
                    </Stack>
                    <Div sx={{
                        display: 'flex',
                        minWidth: 0,
                        flexDirection: 'column',
                        alignItems: 'center',
                        alignSelf: 'center',
                        width: '240px',
                        maxWidth: '100%',
                    }}
                    >
                        <Button
                            component={RouterLink}
                            to={`/budget/${item.id}`}
                            variant={'contained'}
                            size={"small"}
                        >
                            Access Budget
                        </Button>
                    </Div>
                </Div>
                <Divider sx={{my: 2}}/>
                <Div sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'flex-end',
                    // py: theme => theme.spacing(1)
                }}>
                    <Stack direction={"row"} alignItems={"center"} sx={{p: 0}}>
                        <Item>
                            <Typography variant={"h6"} mb={0}>{`$${formatCash(stats.expenses)}`}</Typography>
                            <TrendingDown color={"error"}/>
                        </Item>
                        <Item>
                            <Typography variant={"h6"}
                                        mb={0}>{`$${formatCash(stats.revenues ? stats.revenues : 0)}`}</Typography>
                            <TrendingUpIcon color={"success"}/>
                        </Item>
                    </Stack>
                </Div>
            </JumboCardQuick>
        </React.Fragment>
    );
};

export default EventItemCard;